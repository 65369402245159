<template src="./ForgotPassword.htm">
 


</template>

<script>
import Form from '@/utils/form'
import { mapState } from 'vuex';
export default {
  data() {
        return {
            show1: false,
            busy: false,
            emailRules: [ 
              v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],
            form: new Form({
                name: '',
                email: '',
                message:'',
            })
        }
    },
  methods: {
    validate () {
      this.busy = true;
      this.$api
          .post('/auth/forgot-password', this.form)
          .then(res => {
            this.busy = false;
            this.$toast.open({
                message:  'We have e-mailed your temporary password',
                type: 'success',
                position: 'bottom'
            })
          })
          .catch(err => {
            this.busy = false;
            this.$toast.open({
                message:  err.response.data.msg,
                type: 'error',
                position: 'bottom'
            })
          })
    },   
  },
};
</script>